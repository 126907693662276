<template>
  <div class="modal" :class="{ show: true }">
    <transition name="modal">
      <div v-if="load" class="modal__content" @click.stop>
        <div class="modal__header">
          <div class="text__16 text__gray4">
            Добавить генератор числа к пользователю
          </div>
          <button class="modal__header__button" @click.prevent="$emit('close')">
            <img src="@/assets/images/svg/close.svg" alt="" />
          </button>
        </div>
        <div>
          <div class="modal__body">
            <select v-model="selected">
              <option disabled value="">Выберите один из вариантов</option>
              <option v-for="item in users" :key="item.id">
                {{ item.email }}
              </option>
            </select>
            <!--            <div style="margin: 24px 0">-->
            <!--              <IRange :count="count" @newValue="(val) => (count = val)" />-->
            <!--              <div style="margin: 12px 0 0">-->
            <!--                {{ count }} {{ textNumber(count) }}-->
            <!--              </div>-->
            <!--            </div>-->

            <IText
              :value="String(predict)"
              @customOnInput="(val) => (predict = String(val))"
            /><!--
            <INumberSmall
              :value="String(predict)"
              @customOnInput="(val) => (predict = Number(val))"
            />-->
          </div>
          <div class="modal__footer">
            <ButtonLilac name="Применить" @click="clickGenerate" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import getUser from "@/mixins/request/user/getUser";
import textNumerals from "@/mixins/textNumerals";
import IText from "@/components/ui/input/IText.vue";

export default {
  name: "MAddNumber",
  emits: ["close", "clickGenerate"],
  mixins: [getUser, textNumerals],
  components: { IText },
  data() {
    return {
      load: false,
      predict: "0",
      selected: "",
      count: 1,
    };
  },
  mounted() {
    this.getUsers();
    this.load = true;
  },
  methods: {
    clickGenerate() {
      const userFind = this.users.find((item) => item.email === this.selected);
      this.$emit("clickGenerate", {
        user_id: userFind.id,
        predict: this.predict,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  width: 100%;
  border: 1px solid $lilac;
  border-radius: 6px;
  padding: 12px 16px;
  margin: 0 0 12px;
}
</style>
