<template>
  <button
    class="text__button"
    :class="{ disabled: disabled }"
    :type="type"
    :disabled="disabled"
  >
    {{ name }}
  </button>
</template>

<script>
export default {
  name: "ButtonLilacThin",
  props: {
    name: { type: String, required: true },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  position: relative;
  margin: 13px 24px 0;
  height: 17px;
  color: $lilac;
  transition: 0.1s ease-in;
  font-size: 14px;
  border-bottom: 1px solid $lilac;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
