<template>
  <section class="admin">
    <div class="admin__content">
      <div class="admin__tab">
        <div class="admin__tab__title">
          <button @click="tab_active = 0" :class="{ active: tab_active === 0 }">
            Генератор чисел
          </button>
          <button @click="tab_active = 1" :class="{ active: tab_active === 1 }">
            Выбор победителя VK
          </button>
          <button @click="tab_active = 2" :class="{ active: tab_active === 2 }">
            Выбор победителя Instagram
          </button>
          <!--          <button @click="tab_active = 2" :class="{ active: tab_active === 2 }">-->
          <!--            Выбор победителя Instagram-->
          <!--          </button>-->
          <button @click="tab_active = 3" :class="{ active: tab_active === 3 }">
            Все пользователи
          </button>
        </div>
        <div v-if="tab_active === 0" class="admin__tab__content">
          <TGeneratorNumber />
        </div>
        <div v-if="tab_active === 1" class="admin__tab__content">
          <TGeneratorVk />
        </div>
        <div v-if="tab_active === 2" class="admin__tab__content">
          <TGeneratorIg />
        </div>
        <div v-if="tab_active === 3" class="admin__tab__content">
          <AllUsers />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TGeneratorNumber from "@/components/pages/admin/TGeneratorNumber";
import AllUsers from "@/components/pages/admin/AllUsers";
import TGeneratorVk from "@/components/pages/admin/TGeneratorVk";
import TGeneratorIg from "@/components/pages/admin/TGeneratorIg";

export default {
  name: "Admin",
  components: { TGeneratorIg, TGeneratorVk, AllUsers, TGeneratorNumber },
  data() {
    return {
      tab_active: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.admin {
  &__content {
  }

  .admin__tab {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 8px;
    &__title {
      border-right: 1px solid $lilac;
      @include disflex(flex, stretch, flex-start);
      flex-direction: column;
      gap: 16px;
      padding: 0 8px 0 0;
      button {
        &.active {
          color: $lilac;
          border-bottom: 1px solid #5e6086;
        }
      }
    }
    &__content {
      width: 100%;
    }
  }
}
</style>
