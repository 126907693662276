<template>
  <div class="banner__wrapper">
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=104
"
    />
    <section class="ask">
      <Form
        class="ask__content"
        @submit="clickGenerate"
        :validation-schema="formAsk"
      >
        <div class="ask__title text__black text__h1 adaptive">Шар молчит</div>
        <div class="ball">
          <img src="@/assets/images/ball/ball-default.png" alt="" />
          <img
            v-show="!generate"
            class="ball__emp"
            src="@/assets/images/ball/ball_emp.png"
            alt=""
          />
          <img
            v-show="generate"
            class="ball__pred"
            src="@/assets/images/ball/ball_pred.png"
            alt=""
          />
          <span
            v-show="generate"
            class="ball__text text__10"
            style="color: white; width: 60px; text-align: center"
            >{{ text }}</span
          >
        </div>
        <ButtonLilac name="Сгенерировать" style="margin: 24px 0" type="text" />
        <div style="text-align: center; width: 100%; max-width: 400px">
          <div class="text__14 adaptive" style="margin: 0 0 8px">
            новое предсказание или
          </div>
          <div class="text__14 adaptive" style="margin: 0 0 8px">
            ответ на вопрос
          </div>
          <IBase
            :label="true"
            name="question"
            type="text"
            placeholder="Вопрос"
          />
        </div>
      </Form>
    </section>
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=104
"
    />
  </div>
</template>

<script>
import IBase from "@/components/ui/input/IBase";
import checkAuthForCallBack from "@/mixins/checkAuthForCallBack";
import BannerLink from "@/components/BannerLink";

const answer = [
  { id: 0, text: "Подумай и спроси опять" },
  { id: 1, text: "Лучше я промолчу" },
  { id: 2, text: "Да" },
  { id: 3, text: "Можешь быть уверен в этом" },
  { id: 4, text: "По моим данным нет" },
];

export default {
  name: "Ask",
  components: { BannerLink, IBase },
  mixins: [checkAuthForCallBack],
  data() {
    const formAsk = this.$yup.object().shape({
      question: this.$yup.string().required(),
    });
    return {
      formAsk,
      generate: false,
      text: "",
    };
  },
  methods: {
    clickGenerate(values, { resetForm }) {
      this.checkAuthForCallBack(() => {
        this.text = answer[Math.floor(Math.random() * 4)].text;
        this.generate = true;
        resetForm({ values: "" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ask {
  &__title {
    margin: 0 0 24px;
  }

  &__content {
    @include disflex(flex, center, center);
    flex-direction: column;

    .ball {
      position: relative;
      @include disflex(flex, center, center);
      width: 281px;
      height: 281px;

      .ball__emp,
      .ball__pred,
      .ball__text {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
