<template>
  <div class="tab__generator">
    <TBase title="Все пользователи" :count="users.length">
      <template #thead>
        <th>ID</th>
        <th>Пользователь</th>
        <th>Email</th>
        <th>Роль</th>
        <th>Дата создания</th>
      </template>
      <template #tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.id }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.is_superadmin ? "Админ" : "Пользователь" }}</td>
          <td>{{ user.created_at }}</td>
        </tr>
      </template>
    </TBase>
  </div>
</template>

<script>
import getUser from "@/mixins/request/user/getUser";
import TBase from "@/components/ui/table/TBase";

export default {
  name: "AllUsers",
  components: { TBase },
  mixins: [getUser],
  mounted() {
    this.getUsers();
  },
  methods: {},
};
</script>

<style scoped></style>
