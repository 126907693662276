<template>
  <label>
    <input
      class="text__12"
      type="number"
      :value="value"
      @input="$emit('customOnInput', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: "INumberSmall",
  emits: ["customOnInput"],
  props: ["value"],
};
</script>

<style lang="scss" scoped>
label {
  position: relative;
  height: 100%;
  display: block;
  input[type="number"] {
    padding: 6px 12px;
    border: 1px solid $lilac_l1;
    border-radius: 8px;
    width: 100%;
    max-height: 36px;
    -moz-appearance: textfield;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
