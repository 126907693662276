<template>
  <div class="banner__wrapper">
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=103
"
    />
    <section class="password">
      <div class="password__content">
        <div class="text__h1 adaptive">{{ password }}</div>
        <ButtonLilac
          style="margin: 20px 0"
          name="Сгенерировать"
          @click="generatePassword"
        />
        <div style="text-align: center">
          <div class="text__16" style="margin: 0 0 20px">
            новый пароль длиной
          </div>
          <IRange
            :count="count"
            min="6"
            max="16"
            @newValue="(val) => (count = Number(val))"
          />
          <div style="margin: 20px 0 0">
            {{ count }} {{ textSymbols(count) }}
          </div>
        </div>
        <div class="password__setup">
          <span>используя</span>
          <ICheckbox label="цифры" value="number" v-model="selectPremium" />
          <ICheckbox
            label="спец символы"
            value="special_characters"
            v-model="selectPremium"
          />
        </div>
      </div>
    </section>
    <BannerLink
        link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=103
"
    />
  </div>
</template>

<script>
import IRange from "@/components/ui/input/IRange";
import ICheckbox from "@/components/ui/input/ICheckbox";
import checkAuthForCallBack from "@/mixins/checkAuthForCallBack";
import textNumerals from "@/mixins/textNumerals";
import BannerLink from "@/components/BannerLink";

export default {
  name: "Password",
  components: { BannerLink, ICheckbox, IRange },
  mixins: [checkAuthForCallBack, textNumerals],
  data() {
    return {
      password: "",
      count: 8,
      selectPremium: [],
    };
  },
  mounted() {
    this.generatePasswordStart();
  },
  methods: {
    generatePassword() {
      this.checkAuthForCallBack(() => {
        this.generatePasswordStart();
      });
    },
    generatePasswordStart() {
      let number = this.selectPremium.find((item) => item === "number")
        ? "0123456789"
        : "";
      let words = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      let special_characters = this.selectPremium.find(
        (item) => item === "special_characters"
      )
        ? "~!@-#$"
        : "";
      let charset = `${number}${words}${special_characters}`;
      if (window.crypto && window.crypto.getRandomValues) {
        return (this.password = Array(this.count)
          .fill(charset)
          .map(
            (x) =>
              x[
                Math.floor(
                  (crypto.getRandomValues(new Uint32Array(1))[0] /
                    (0xffffffff + 1)) *
                    (x.length + 1)
                )
              ]
          )
          .join(""));
      } else {
        let res;
        res = "";
        for (var i = 0, n = charset.length; i < this.count; ++i) {
          res += charset.charAt(Math.floor(Math.random() * n));
        }
        return (this.password = res);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  height: 100%;
  &__content {
    @include disflex(flex, center, center);
    flex-direction: column;
    height: 100%;
  }

  &__setup {
    margin: 16px 0 0;
    @include disflex(flex, stretch, center);
    gap: 16px;
  }
}
</style>
