<template>
  <div class="table__wrapper">
    <div class="table__title text__h3 adaptive">
      {{ title }} <span class="text__gray3">{{ count }}</span>
    </div>
    <div class="table__scroll">
      <table>
        <thead>
          <slot name="thead" />
        </thead>
        <tbody>
          <slot name="tbody" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TBase",
  props: {
    title: {
      type: String,
      require: true,
    },
    count: {
      type: Number,
      require: true,
    },
  },
};
</script>

<style lang="scss">
.table__wrapper {
  position: relative;
  overflow: hidden;

  .table__title {
    margin: 0 0 24px;
    span {
      margin: 0 0 0 6px;
    }
  }

  ::-webkit-scrollbar {
    border-top: 6px solid white;
    border-bottom: 6px solid white;
    border-right: 8px solid white;
    border-left: 8px solid white;
    height: 16px;
    background: #ebebf5;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border: 1px solid #e5e5f3;
    background-clip: content-box;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 10px #b5b6d2;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #b5b6d2;
    border: 6px solid transparent;
    background-clip: content-box;
    //&:hover{
    //  background: #b5b6d2;
    //}
  }
}
.table__scroll {
  min-height: 200px;
  overflow: scroll;
  width: 100%;
  height: 100%;

  table,
  tr,
  td {
    border-collapse: collapse;
  }

  table {
    //display: block;
    width: 100%;
    //table-layout: fixed;

    thead {
      background: rgba(181, 182, 210, 0.12);
      border-bottom: 2px solid #e5e5f3;

      th {
        padding: 10px 16px;
        white-space: nowrap;
        text-align: left;
        font-weight: normal;
      }
      .thead__filter {
        cursor: pointer;
        @include disflex(flex, stretch, center);
        span {
          margin: 0 4px 0 0;
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid #e5e5f3;
          padding: 12px 16px;
        }
      }

      .tbody__categories {
        display: flex;
        gap: 8px;
      }

      .tbody__authors {
        .tbody__author {
          margin: 0 0 8px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
