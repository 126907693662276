<template>
  <section style="position: relative; overflow: hidden">
    <ButtonLilac
      name="+ Добавить"
      style="margin: 0 0 32px"
      @click="showAddNumber = true"
    />
    <TBase
      v-if="pull_number.length"
      title="Все пользователи для генерации числа"
      :count="pull_number.length"
    >
      <template #thead>
        <th>ID user</th>
        <th>Предсказывать для</th>
        <th>Успех</th>
        <th>Weight</th>
        <th>Дата создания</th>
        <!--        <th>Дата последнего обновления</th>-->
        <th>Действия</th>
      </template>
      <template #tbody>
        <tr v-for="number in pull_number" :key="number.id">
          <td>{{ number.user_id }}</td>
          <td>{{ number.predict }}</td>
          <td>{{ number.is_success }}</td>
          <td>{{ number.weight }}</td>
          <td>{{ $moment(number.created_at).format("LLL") }}</td>
          <!--          <td>{{ $moment(number.updated_at).format("LLL") }}</td>-->
          <td class="table__move">
            <!--            <button>Редактировать</button>-->
            <button @click="deletePullNumber(Number(number.id))">
              Удалить
            </button>
          </td>
        </tr>
      </template>
    </TBase>
    <div v-else class="noting">Нет пользователей</div>
    <MAddNumberVK
      v-if="showAddNumber"
      @close="showAddNumber = false"
      @clickGenerate="clickGenerate"
    />
  </section>
</template>

<script>
import TBase from "@/components/ui/table/TBase";
import pull_number_vk from "@/mixins/request/pull_number_vk";
import MAddNumberVK from "@/components/ui/modal/MAddNumberVK";

export default {
  name: "TGeneratorVk",
  components: { MAddNumberVK, TBase },
  mixins: [pull_number_vk],
  data() {
    return {
      showAddNumber: false,
    };
  },
  mounted() {
    this.getPullNumber();
  },
  methods: {
    async clickGenerate(request) {
      const data = await this.$api.number_vk.postPullNumber(request);
      if (data?.length) {
        for (let item of data) {
          this.$toast(item.message);
        }
      } else {
        this.showAddNumber = false;
        await this.getPullNumber();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table__move {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  button {
    background: $gray4;
    color: white;
    padding: 6px;
    border-radius: 6px;
  }
}
</style>
