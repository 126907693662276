<template>
  <div class="modal" :class="{ show: true }">
    <transition name="modal">
      <div v-if="load" class="modal__content" @click.stop>
        <div class="modal__header">
          <div class="text__16 text__gray4">Добавить число к поль-ю ВК</div>
          <button class="modal__header__button" @click.prevent="$emit('close')">
            <img src="@/assets/images/svg/close.svg" alt="" />
          </button>
        </div>
        <div>
          <div class="modal__body">
            {{ form }}
            <select v-model="form.selected">
              <option disabled value="">Выберите один из вариантов</option>
              <option v-for="item in users" :key="item.id">
                {{ item.email }}
              </option>
            </select>
            <IBase
              :label="true"
              name="win_number"
              type="text"
              :value="form.vk_user_id"
              @eventInput="(val) => (form.vk_user_id = val)"
              placeholder="Введите id или sreen_name"
            />
            <IBase
              :label="true"
              name="win_number"
              type="text"
              :value="form.weight"
              @eventInput="(val) => (form.weight = Number(val))"
              placeholder="Введите weight"
            />
          </div>
          <div class="modal__footer">
            <ButtonLilac name="Применить" @click="clickGenerate" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import getUser from "@/mixins/request/user/getUser";
import textNumerals from "@/mixins/textNumerals";
import IBase from "@/components/ui/input/IBase";

export default {
  name: "MAddNumberVK",
  emits: ["close", "clickGenerate"],
  mixins: [getUser, textNumerals],
  components: { IBase },
  data() {
    return {
      load: false,
      form: {
        selected: "",
        weight: 0,
        vk_user_id: "",
      },
    };
  },
  mounted() {
    this.getUsers();
    this.load = true;
  },
  methods: {
    clickGenerate() {
      const userFind = this.users.find(
        (item) => item.email === this.form.selected
      );
      this.$emit("clickGenerate", {
        user_id: userFind.id,
        vk_user_id: this.form.vk_user_id,
        weight: this.form.weight,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  width: 100%;
  border: 1px solid $lilac;
  border-radius: 6px;
  padding: 12px 16px;
  margin: 0 0 12px;
}
</style>