<template>
  <div class="banner__wrapper">
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=88
"
    />
    <section class="win">
      <div class="win__content">
        <!--        <h1 class="text__h2">Выбор победителей Вконтакте или Instagram</h1>-->
        <h1 class="text__h2">Выбор победителей Вконтакте или Instagram</h1>
        <h4 class="text__14 adaptive">
          Доверьте выбор победителя своего розыгрыша генератору №1 в рунете.
        </h4>
        <!--        <Winner />-->
        <PickingWinnerVK />
      </div>
    </section>
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=88
"
    />
  </div>
</template>

<script>
// import PickingWinnerVK from "@/components/PickingWinnerVK
import BannerLink from "@/components/BannerLink";
import PickingWinnerVK from "@/components/PickingWinnerVK.vue";

export default {
  name: "Win",
  components: { PickingWinnerVK, BannerLink },
};
</script>

<style scoped lang="scss">
.win {
  height: 100%;

  &__content {
    @include disflex(flex, center, center);
    flex-direction: column;
    height: 100%;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    h4 {
      margin: 4px 0 24px;
    }
    h3 {
      margin: 30px 0 4px;
    }
    label {
      margin: 0 0 20px;
      width: 100%;
    }
  }
}
</style>
