export default {
  data() {
    return {
      pull_number: [],
      waitResponsePullNumber: false,
    };
  },
  methods: {
    async getPullNumber() {
      this.waitResponsePullNumber = true;
      this.pull_number = await this.$api.number.getPullNumber();
      this.waitResponsePullNumber = false;
    },
    async deletePullNumber(id) {
      await this.$api.number.deletePullNumberId(id);
      await this.getPullNumber();
    },
  },
};
