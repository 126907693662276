import { mapActions } from "vuex";

export default {
  data() {
    return {
      generator_number: 20,
      generator_setup: {
        from: 1,
        to: 1000,
        count: 1,
        maxCount: 200,
        excludeNumbersStr: "",
        excludeNumbers: [],
      },
      waitResponseGeneratorNumber: false,
    };
  },
  methods: {
    ...mapActions(["CHECK__AUTH", "SHOW_OR_HIDE_MODAL_LOGIN", "AUTH"]),
    async generatorNumber({ count, from, to, excludeNumbers }) {
      this.waitResponseGeneratorNumber = true;
      let data = null;
      try {
        data = await this.$api.generator.postGeneratorNumber({
          count,
          from,
          to,
          excludeNumbers,
        });
      } catch (e) {
        console.log(e);
        console.log("console.log(e)");
        /*localStorage.removeItem("token");*/
      }
      if (data) {
        if (data.message && data.message === "401") {
          this.CHECK__AUTH();
          if (!this.AUTH) this.SHOW_OR_HIDE_MODAL_LOGIN(true);
        } else {
          this.generator_number = data;
        }
      } else {
        this.$toast("Ошибка получения генерированного числа");
      }
      this.waitResponseGeneratorNumber = false;
    },
    generatorReset() {
      this.generator_number = 0;
      /*this.generator_setup.from = 1;
                        this.generator_setup.to = 1000;
                        this.generator_setup.count = 1;
                        this.generator_setup.maxCount = 200;
                        this.generator_setup.excludeNumbers = [];
                        this.generator_setup.excludeNumbersStr = "";*/
    },
  },
};

/*export default {
  data() {
    return {
      generator_number: 20,
      generator_setup: {
        from: 0,
        to: 1000,
        count: 1,
      },
      waitResponseGeneratorNumber: false,
    };
  },
  methods: {
    async generatorNumber({ count, from, to }) {
      this.waitResponseGeneratorNumber = true;
      const data = await this.$api.generator.postGeneratorNumber({
        count,
        from,
        to,
      });
      data
        ? (this.generator_number = data)
        : this.$toast("Ошибка получения генерированного числа");
      this.waitResponseGeneratorNumber = false;
    },
  },
};*/
