export const WINNER_IG_TYPE_POST = "BY_POST";
export const WINNER_IG_TYPE_SUBSCRIBERS = "BY_SUBSCRIBERS";

export const STEP_FIRST = "STEP_FIRST";
export const STEP_TWO = "STEP_TWO";
export const STEP_THREE = "STEP_THREE";
export const STEP_FOUR = "STEP_FOUR";
export const SEARCH_TYPE_LIKE_STEP_TWO = "like";
export const SEARCH_TYPE_COMMENTS_STEP_TWO = "comment";
export const SEARCH_TYPE_SUBSCRIPTION_STEP_TWO = "S_T_S";
export const IS_CHECK_SUBSCRIPTION_STEP_TWO = "subscribe";
export const IS_CHECK_LIKE_STEP_TWO = "like";
