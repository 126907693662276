<template>
  <div class="banner__wrapper">
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=105
"
    />
    <section class="question">
      <div class="question__content">
        <h1 class="text__h2" style="margin: 0 0 20px">Вопросы</h1>
        <div cclass="text__h3" :class="{ text__h1: DESKTOP }">
          {{ question.text }}
        </div>
        <div class="question__answer" :class="{ disabled: disabled_answer }">
          <button
            v-for="ans in answers"
            :key="ans.id"
            :class="{ success: ans.success, error: ans.error }"
            @click="getAnswer(ans, $event)"
          >
            {{ ans.text }}
          </button>
        </div>
        <ButtonLilac
          name="Сгенерировать"
          @click="
            checkAuthForCallBack(() => {
              getQuestion();
            })
          "
        />
        <div class="question__progress">
          <button class="button__restart" @click="clickRestart">
            <img src="@/assets/images/svg/restart.svg" alt="restart" />
          </button>
          <div class="question__progress__line">
            <span :style="{ width: win ? win + '%' : '50%' }" />
          </div>
          <div style="margin: 16px 0 0">
            Верно {{ answers_count.win }} и неверно {{ answers_count.fail }}
          </div>
        </div>
      </div>
    </section>
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=105
"
    />
  </div>
</template>

<script>
import questions from "@/db/questions.json";
import ButtonLilac from "@/components/ui/button/ButtonLilac";
import checkAuthForCallBack from "@/mixins/checkAuthForCallBack";
import BannerLink from "@/components/BannerLink";
import { mapGetters } from "vuex";

export default {
  name: "Fact",
  mixins: [checkAuthForCallBack],
  components: { BannerLink, ButtonLilac },
  data() {
    return {
      question: {},
      answers: [],
      disabled_answer: false,
      answers_count: {
        total: 0,
        win: 0,
        fail: 0,
      },
    };
  },
  mounted() {
    this.getQuestion();
  },
  computed: {
    ...mapGetters(["DESKTOP"]),
    win() {
      return Math.floor(
        (this.answers_count.win / this.answers_count.total) * 100
      );
    },
  },
  methods: {
    clickRestart() {
      this.answers_count = {
        total: 0,
        win: 0,
        fail: 0,
      };
      this.getQuestion();
    },
    getAnswer(myAnswer) {
      this.checkAuthForCallBack(() => {
        const true_answer_id = this.question.answer_true,
          answers = this.answers;

        // отключаем возможность выбирать ответ
        this.disabled_answer = true;

        // находим правильный ответ и меняем его
        const trueIdAnswer = answers.findIndex((a) => a.id === true_answer_id);
        answers[trueIdAnswer].success = 1;

        // если выбран неверный ответ, то красим его в красный
        if (myAnswer.id !== true_answer_id) {
          const failIdAnswer = answers.findIndex((a) => a.id === myAnswer.id);
          answers[failIdAnswer].error = 1;
          this.answers_count.fail++;
        } else {
          this.answers_count.win++;
        }

        this.answers_count.total++;
      });
    },
    getQuestion() {
      let randomNumber = Math.floor(Math.random() * questions.length);
      this.question = questions[randomNumber];
      this.answers = this.question.answers.map((ans) => {
        return {
          text: ans.text,
          id: ans.id,
          success: 0,
          error: 0,
        };
      });
      this.disabled_answer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  height: 100%;

  &__content {
    @include disflex(flex, center, center);
    flex-direction: column;
    height: 100%;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    h2 {
      margin: 20px 0 0;
    }
  }

  &__answer {
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    &.disabled {
      pointer-events: none;
    }

    button {
      &.success {
        color: $blue_d1;
      }
      &.error {
        color: $red;
      }
    }
  }

  &__progress {
    position: relative;
    margin: 20px 0 0;
    max-width: 200px;
    width: 100%;

    .button__restart,
    &__line span {
      position: absolute;
      content: "";
    }

    .button__restart {
      right: calc(100% + 16px);
      top: 50%;
      transform: translateY(-50%);
    }

    &__line {
      position: relative;
      height: 4px;
      width: 100%;
      background: #e8e8e8;
      border-radius: 90px;
      span {
        top: 0;
        left: 0;
        height: 100%;
        background: $lilac;
      }
    }
  }
}
</style>
