<template>
  <div class="banner__wrapper">
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=197"
    />
    <section class="fact">
      <div class="fact__content">
        <h2 class="text__h2">Факты</h2>
        <h1 class="text__h3" :class="{ text__h1: DESKTOP }">
          {{ fact.text }}
        </h1>
        <ButtonLilac
          name="Сгенерировать"
          @click="checkAuthForCallBack(() => getFact())"
        />
      </div>
    </section>
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=197"
    />
  </div>
</template>

<script>
import facts from "@/db/facts.json";
import ButtonLilac from "@/components/ui/button/ButtonLilac";
import checkAuthForCallBack from "@/mixins/checkAuthForCallBack";
import BannerLink from "@/components/BannerLink";
import { mapGetters } from "vuex";

export default {
  name: "Fact",
  mixins: [checkAuthForCallBack],
  components: { BannerLink, ButtonLilac },
  data() {
    return {
      fact: "",
    };
  },
  mounted() {
    this.getFact();
  },
  computed: { ...mapGetters(["DESKTOP"]) },
  methods: {
    getFact() {
      let randomNumber = Math.floor(Math.random() * facts.length);
      this.fact = facts[randomNumber];
    },
  },
};
</script>

<style lang="scss" scoped>
.fact {
  height: 100%;

  &__content {
    @include disflex(flex, center, center);
    flex-direction: column;
    height: 100%;

    h1 {
      margin: 40px 0 20px;
      max-width: 500px;
      text-align: center;
    }
  }
}
</style>
