<template>
  <section style="position: relative; overflow: hidden">
    <ButtonLilac
      name="+ Добавить"
      style="margin: 0 0 32px"
      @click="showAddNumber = true"
    />
    <TBase
      v-if="pull_number.length"
      title="Все пользователи для генерации числа"
      :count="pull_number.length"
    >
      <template #thead>
        <th>ID user</th>
        <th>Предсказывать для</th>
        <th>Ссылка\Логин</th>
        <th>Успех</th>
        <th>Weight</th>
        <th>Дата создания</th>
        <!--        <th>Дата последнего обновления</th>-->
        <th>Действия</th>
      </template>
      <template #tbody>
        <tr v-for="number in pull_number" :key="number.id">
          <td>{{ number.user_id }}</td>
          <td>{{ number.predict }}</td>
          <td>
            <a
              target="_blank"
              :href="
                validURL(number.link)
                  ? number.link
                  : 'https://instagram.com/' + number.link
              "
              >{{ number.link }}</a
            >
          </td>
          <td>{{ number.is_success }}</td>
          <td>{{ number.weight }}</td>
          <td>{{ $moment(number.created_at).format("LLL") }}</td>
          <!--          <td>{{ $moment(number.updated_at).format("LLL") }}</td>-->
          <td class="table__move">
            <!--            <button>Редактировать</button>-->
            <button @click="deletePullNumberIg(Number(number.id))">
              Удалить
            </button>
          </td>
        </tr>
      </template>
    </TBase>
    <div v-else class="noting">Нет пользователей</div>
    <MAddIG
      v-if="showAddNumber"
      @close="showAddNumber = false"
      @clickGenerate="clickGenerate"
    />
  </section>
</template>

<script>
import TBase from "@/components/ui/table/TBase";
import pull_number_ig from "@/mixins/request/pull_number_ig";
import MAddIG from "@/components/ui/modal/MAddIG.vue";

export default {
  name: "TGeneratorIg",
  components: { MAddIG, TBase },
  mixins: [pull_number_ig],
  data() {
    return {
      showAddNumber: false,
    };
  },
  mounted() {
    this.getAllPullNumberIg();
  },
  methods: {
    async clickGenerate(request) {
      const data = await this.$api.number_ig.postPullNumberIg(request);
      if (data?.length) {
        for (let item of data) {
          this.$toast(item.message);
        }
      } else {
        this.showAddNumber = false;
        await this.getAllPullNumberIg();
      }
    },
    validURL(str) {
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.table__move {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  button {
    background: $gray4;
    color: white;
    padding: 6px;
    border-radius: 6px;
  }
}
</style>
