const text = {
  number: ["случайное число", "случайных числа", "случайных чисел"],
  symbols: ["символ", "символа", "символов"],
};

export default {
  methods: {
    textNumber(length) {
      return this.declOfNum(length, text.number);
    },
    textSymbols(length) {
      return this.declOfNum(length, text.symbols);
    },
    declOfNum(number, titles) {
      const cases = [2, 0, 1, 1, 1, 2];
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },
  },
};
