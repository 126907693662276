<template>
  <label>
    <input
      class="text__12"
      type="text"
      :value="value"
      :disabled="disabled"
      @input="$emit('customOnInput', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: "IText",
  emits: ["customOnInput"],
  props: {
    value: { type: String },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
label {
  position: relative;
  height: 100%;
  display: block;

  input[type="text"] {
    padding: 6px 12px;
    border: 1px solid $lilac_l1;
    border-radius: 8px;
    width: 100%;
    max-height: 36px;
    -moz-appearance: textfield;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
