<template>
  <div>
    <p class="block__subtitle d-md-none">Мы начали сбор и обработку данных</p>
    <div
      v-if="search_by === SEARCH_TYPE_LIKE_STEP_TWO()"
      class="block__subtitle d-md-none"
    >
      <span class="block__subtitle-item">Лайков:</span
      ><span class="block__subtitle-item">{{ likes }}</span>
    </div>
    <div
      v-if="search_by === SEARCH_TYPE_COMMENTS_STEP_TWO()"
      class="block__subtitle d-md-none"
    >
      <span class="block__subtitle-item">Комментариев:</span
      ><span class="block__subtitle-item">{{ comments }}</span>
    </div>
    <div
      v-if="search_by === SEARCH_TYPE_SUBSCRIPTION_STEP_TWO()"
      class="block__subtitle d-md-none"
    >
      <span class="block__subtitle-item">Подписчиков:</span
      ><span class="block__subtitle-item">{{ subscribers }}</span>
    </div>
    <div class="block__loading">
      <span class="block__loading-progress">100%</span>
      <div class="block__loading-bar" style="width: 100%"></div>
    </div>
  </div>
</template>
<script>
import {
  SEARCH_TYPE_COMMENTS_STEP_TWO,
  SEARCH_TYPE_LIKE_STEP_TWO,
  SEARCH_TYPE_SUBSCRIPTION_STEP_TWO,
} from "@/helpers/ig_winner";

export default {
  name: "WinnerIgThreeStep",
  methods: {
    SEARCH_TYPE_SUBSCRIPTION_STEP_TWO() {
      return SEARCH_TYPE_SUBSCRIPTION_STEP_TWO;
    },
    SEARCH_TYPE_COMMENTS_STEP_TWO() {
      return SEARCH_TYPE_COMMENTS_STEP_TWO;
    },
    SEARCH_TYPE_LIKE_STEP_TWO() {
      return SEARCH_TYPE_LIKE_STEP_TWO;
    },
  },
  props: {
    likes: Number,
    comments: Number,
    subscribers: Number,
    search_by: String,
  },
};
</script>

<style></style>
