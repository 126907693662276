export default {
  data() {
    return {
      users: {},
      waitResponseGetUser: false,
    };
  },
  methods: {
    async getUsers() {
      this.waitResponseGetUser = true;
      this.users = await this.$api.user.getUser();
      this.waitResponseGetUser = false;
    },
  },
};
