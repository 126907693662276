<template>
  <div>
    <div class="base-winner">
      <div class="base-winner__top">
        <div class="base-winner__top-wrapper">
          <div class="base-winner__number">1</div>
          <span class="base-winner__border"></span
          ><a
            :href="
              winner !== null
                ? `https://www.instagram.com/${winner.username}`
                : ''
            "
            target="_blank"
            class="_winner user _name base-winner__user"
          >
            <div class="user__picture">
              <img src="/avatar.svg" alt="img" class="user__img" />
            </div>
            <div class="user__main">
              <span class="user__name">{{
                winner !== null ? winner.username : "Сейчас мы его узнаем"
              }}</span>
            </div>
          </a>
          <!--          <span class="base-winner__win_number d-none d-md-flex">№8</span>-->
        </div>
        <div class="base-winner__top-info">
          <span class="base-winner__border _mod"></span
          ><!--<span class="base-winner__win_number">№8</span>-->
          <button
            class="base-winner__btn btn _blue"
            @click="$emit('generate-winner')"
          >
            {{ winner !== null ? "Выбрать другого" : "Выбрать победителя" }}
          </button>
        </div>
      </div>
      <!--      <div v-if="winner !== null" class="base-winner__bottom">-->
      <div v-if="false" class="base-winner__bottom">
        <!---->
        <div class="base-winner__checks">
          <!----><!---->
          <div class="base-winner__checks-wrapper">
            <button type="button" class="base-winner__check">
              <span class="base-winner__check-title"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="base-winner__check-svg"
                >
                  <use xlink:href="#ico-like"></use>
                </svg>
                Лайк:</span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="base-winner__check-ico"
              >
                <use xlink:href="#ico-checked"></use>
              </svg>
            </button>
            <button type="button" class="base-winner__check">
              <span class="base-winner__check-title"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="base-winner__check-svg"
                >
                  <use xlink:href="#ico-people"></use></svg
                ><span>Подписка:</span
                ><!----></span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="base-winner__check-ico"
              >
                <use xlink:href="#ico-checked"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WinnerIgFourStep",
  methods: {},
  props: {
    winner: Object,
  },
};
</script>

<style></style>
