<template>
  <div class="number banner__wrapper">
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=68"
    />
    <div class="number__content">
      <div class="text__h3">Случайное число:</div>
      <div class="number__number">
        <transition name="text" mode="out-in">
          <h1
            v-if="!waitResponseGeneratorNumber"
            class="text__number"
            :class="{ length__40: String(generator_number.length) > 4 }"
          >
            {{ generator_number }}
          </h1>
        </transition>
        <Loader v-if="waitResponseGeneratorNumber" />
      </div>
      <ButtonLilac
        name="Сгенерировать"
        @click="checkAuthForCallBack(() => generatorNumber(generator_setup))"
      />
      <ButtonLilacThin name="Сбросить" @click="reset()" />
      <div class="number__range">
        <span>новую последовательность из</span>
        <IRange
          :max="generator_setup.maxCount"
          :count="generator_setup.count"
          :disabled="waitResponseGeneratorNumber"
          @newValue="(val) => (generator_setup.count = Number(val))"
        />
        <span
          >{{ generator_setup.count }}
          {{ textNumber(generator_setup.count) }}</span
        >
      </div>
      <div
        style="margin: 24px 0 0"
        class="number__before__after text__14 text__black"
      >
        <div class="number__before__after__item">
          <span>от</span>
          <INumberSmall
            :value="generator_setup.from"
            :disabled="waitResponseGeneratorNumber"
            :min="1"
            @customOnInput="setFrom"
          />
        </div>
        <div class="number__before__after__item">
          <span>до</span>
          <INumberSmall
            :value="generator_setup.to"
            :disabled="waitResponseGeneratorNumber"
            :min="2"
            @customOnInput="setTo"
          />
        </div>
      </div>
      <div
        style="margin: 24px 0 0"
        class="number__before__after text__14 text__black"
      >
        <div class="number__before__after__item">
          <span>Введите через "," числа которые стоит исключить.</span>
          <IText
            :value="generator_setup.excludeNumbersStr"
            :disabled="waitResponseGeneratorNumber"
            @customOnInput="setExcludeNumbers"
          />
        </div>
      </div>
    </div>
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=68"
    />
  </div>
</template>

<script>
import IText from "@/components/ui/input/IText";
import INumberSmall from "@/components/ui/input/INumberSmall";
import IRange from "@/components/ui/input/IRange";
import generatorNumber from "@/mixins/request/generator/generatorNumber";
import Loader from "@/components/Loader";
import checkAuthForCallBack from "@/mixins/checkAuthForCallBack";
import textNumerals from "@/mixins/textNumerals";
import BannerLink from "@/components/BannerLink";
import ButtonLilac from "@/components/ui/button/ButtonLilac.vue";
import ButtonLilacThin from "@/components/ui/button/ButtonLilacThin.vue";

export default {
  components: {
    ButtonLilacThin,
    ButtonLilac,
    BannerLink,
    Loader,
    IRange,
    INumberSmall,
    IText,
  },
  mixins: [generatorNumber, checkAuthForCallBack, textNumerals],
  data() {
    return {
      tabIsPressed: false,
      to: 2,
    };
  },
  mounted() {
    this.getFirstNumber();
    window.addEventListener("keydown", this.first);
  },
  unmounted() {
    window.removeEventListener("keydown", this.first);
  },
  methods: {
    first(event) {
      if (this.AUTH) {
        if (event.code === "KeyR" && (event.ctrlKey || event.metaKey)) {
          event.preventDefault();
          this.generator_number = 1;
        }
      }
    },
    getFirstNumber() {
      const max = Number(this.generator_setup.from),
        min = Number(this.generator_setup.to);
      this.generator_number = Math.floor(min + Math.random() * (max + 1 - min));
    },
    calculateCountData() {
      let rangeLen = this.generator_setup.to - this.generator_setup.from;
      this.generator_setup.maxCount = rangeLen > 200 ? 200 : rangeLen;
      if (rangeLen < this.generator_setup.count) {
        this.generator_setup.count = rangeLen - 1 < 1 ? 1 : rangeLen - 1;
      }
    },
    setTo(val) {
      let v = Number(val);
      if (v <= 1 || v <= this.generator_setup.from) {
        v = this.generator_setup.from + 1;
      }
      this.generator_setup.to = v;
      this.calculateCountData();
      this.$forceUpdate();
    },
    setFrom(val) {
      this.generator_setup.from = Number(val) < 1 ? 1 : Number(val);
      this.calculateCountData();
    },
    setExcludeNumbers(val) {
      val = val.replace(/[^\d,]+/g, "");
      // replace(/[, ]+/g, " ").trim()
      // val = val.replace(/[,\s]+|[,\s]+/g, '');
      // console.log(/[^\d,]+/g.test(val));
      console.log(val);
      let resultArray = val
        .split(",") // Just split the string.
        .filter(function (item) {
          // Then filter out empty items
          return item !== "";
        });
      resultArray = resultArray.filter(function (item, pos) {
        return resultArray.indexOf(item) === pos;
      });
      this.generator_setup.excludeNumbersStr = resultArray.join(",");
      this.generator_setup.excludeNumbers = resultArray;
    },
    toGenerate() {
      if (this.generator_setup.from >= this.generator_setup.to) {
        this.$toast(
          'Ошибка. Число "от" не может быть больше или равно числу "до".'
        );
      } else {
        this.generatorNumber(this.generator_setup);
      }
    },
    reset() {
      this.generatorReset();
      this.$forceUpdate();
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.number {
  &__content {
    @include disflex(flex, center, center);
    flex-direction: column;
  }

  &__number {
    min-height: 178px;
    margin: 36px 0;
    @include disflex(flex, center, center);
  }

  &__range {
    margin: 24px 0 0;
    @include disflex(flex, center, center);
    flex-direction: column;

    label {
      margin: 12px 0;
      max-width: 400px;
    }
  }

  &__radio {
    margin: 16px 0;
    @include disflex(flex, stretch, center);
  }

  &__before__after {
    @include disflex(flex, stretch, center);

    &__item {
      @include disflex(flex, stretch, center);

      span {
        margin: 0 14px 0 0;
      }

      &:first-child {
        margin: 0 8px 0 0;
      }

      label {
        max-width: 118px;
      }
    }
  }
}

.text__number {
  max-width: 400px;
  font-style: normal;
  font-weight: 600;
  font-size: 150px;
  line-height: 176px;
  @media screen and (max-width: 768px) {
    font-size: 52px;
    line-height: 35px;
  }

  &.length__40 {
    font-size: 40px;
    line-height: 52px;
    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
    }
  }
}
</style>
