export default {
  data() {
    return {
      pull_number: [],
      waitResponsePullNumber: false,
    };
  },
  methods: {
    async getAllPullNumberIg() {
      this.waitResponsePullNumber = true;
      this.pull_number = await this.$api.number_ig.getAllPullNumberIg();
      this.waitResponsePullNumber = false;
    },
    async deletePullNumberIg(id) {
      await this.$api.number_ig.deletePullNumberIdIG(id);
      await this.getAllPullNumberIg();
    },
  },
};
