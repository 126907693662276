<template>
  <div class="banner__wrapper">
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=88
"
    />
    <div class="instagram-winner__body block">
      <div class="block__col">
        <winner-ig-preview
          v-if="active_step !== STEP_THREE()"
          :type="type_draw"
          :preview_info="preview_info"
        />
      </div>
      <div class="block__col _right">
        <h1 class="instagram-winner__title block__title">
          Выбрать победителя Instagram
        </h1>
        <winner-ig-four-step
          v-if="active_step === STEP_FOUR()"
          @generate-winner="generate_winner()"
          :winner="winner"
        />
        <winner-ig-three-step
          v-if="active_step === STEP_THREE()"
          :likes="likes"
          :comments="comments"
          :subscribers="subscribers"
          :search_by="search_type_step_two"
        />
        <winner-ig-two-step
          @parsing-post-winners="parsing_post_winners()"
          @parsing-follower-winners="parsing_follower_winners()"
          :preview="preview_info.preview"
          :type="type_draw"
          :waitResponse="waitResponse"
          v-if="active_step === STEP_TWO()"
          @change-check-like="(status) => (is_check_like_ST = status)"
          @change-check-subscription="
            (status) => (is_check_subscription_ST = status)
          "
          :search_type="search_type_step_two"
          @change-search-type="(type) => (search_type_step_two = type)"
          :is_check_like="is_check_like_ST"
          :is_check_subscription="is_check_subscription_ST"
        />
        <winner-ig-first-step
          v-if="active_step === STEP_FIRST()"
          :type="type_draw"
          :waitResponse="waitResponse"
          @first-step-find-start="first_step_find_start"
          @change-ig-type="
            (val) => {
              if (!waitResponse) {
                type_draw = val;
              }
            }
          "
        />
        <span class="wait-info" v-if="waitResponse"
          >Идет получение информации <br />
          Пожалуйста подождите.</span
        >
      </div>
    </div>
    <svg-icons />
    <BannerLink
      link="https://clicks.affijet.com/click?offer_id=51&partner_id=1551&utm_medium=Affiliate&landing_id=88
"
    />
  </div>
</template>

<script>
import BannerLink from "@/components/BannerLink.vue";
// import "@/assets/scss/winner_block.css";
import SvgIcons from "@/components/ui/SvgIcons.vue";
import WinnerIgPreview from "@/components/WinnerIgPreview.vue";
import WinnerIgFirstStep from "@/components/WinnerIgFirstStep.vue";
import {
  IS_CHECK_LIKE_STEP_TWO,
  IS_CHECK_SUBSCRIPTION_STEP_TWO,
  SEARCH_TYPE_COMMENTS_STEP_TWO,
  SEARCH_TYPE_LIKE_STEP_TWO,
  SEARCH_TYPE_SUBSCRIPTION_STEP_TWO,
  STEP_FIRST,
  STEP_FOUR,
  STEP_THREE,
  STEP_TWO,
  WINNER_IG_TYPE_POST,
  WINNER_IG_TYPE_SUBSCRIBERS,
} from "@/helpers/ig_winner";
import WinnerIgTwoStep from "@/components/WinnerIgTwoStep.vue";
import WinnerIgThreeStep from "@/components/WinnerIgThreeStep.vue";
import WinnerIgFourStep from "@/components/WinnerIgFourStep.vue";

export default {
  name: "InstagramWinner",
  components: {
    WinnerIgFourStep,
    WinnerIgThreeStep,
    WinnerIgTwoStep,
    WinnerIgFirstStep,
    WinnerIgPreview,
    SvgIcons,
    BannerLink,
  },
  data() {
    return {
      type_draw: null,
      search_link: null,
      active_step: null,
      search_type_step_two: null,
      is_check_like_ST: false,
      is_check_subscription_ST: false,
      preview_info: null,
      likes: 0,
      comments: 0,
      subscribers: 0,
      winners_list: [],
      winner: null,
      extra_option_list: [],
      waitResponse: false,
    };
  },
  created() {
    this.active_step = this.STEP_FIRST();
    this.type_draw = WINNER_IG_TYPE_POST;
    this.search_type_step_two = this.SEARCH_TYPE_LIKE_STEP_TWO();
  },
  methods: {
    STEP_FOUR() {
      return STEP_FOUR;
    },
    STEP_THREE() {
      return STEP_THREE;
    },
    STEP_FIRST() {
      return STEP_FIRST;
    },
    STEP_TWO() {
      return STEP_TWO;
    },
    SEARCH_TYPE_COMMENTS_STEP_TWO() {
      return SEARCH_TYPE_COMMENTS_STEP_TWO;
    },
    SEARCH_TYPE_SUBSCRIPTION_STEP_TWO() {
      return SEARCH_TYPE_SUBSCRIPTION_STEP_TWO;
    },
    SEARCH_TYPE_LIKE_STEP_TWO() {
      return SEARCH_TYPE_LIKE_STEP_TWO;
    },
    async first_step_find_start(link) {
      this.waitResponse = true;
      this.search_link = link;
      if (this.type_draw === WINNER_IG_TYPE_POST) {
        if (this.search_link != null && this.search_link.length > 1) {
          const data = await this.$api.generator.getInfoPostByLink({
            link: this.search_link,
          });
          if (data) {
            if (data.status || data["stack-trace"]) {
              this.$toast("Ошибка получения информации о посте");
            } else {
              this.preview_info = data;
              this.active_step = this.STEP_TWO();
            }
          } else {
            this.$toast("Ошибка получения информации о посте");
          }
        }
      } else if (this.type_draw === WINNER_IG_TYPE_SUBSCRIBERS) {
        if (this.search_link != null && this.search_link.length > 1) {
          const data = await this.$api.generator.startAccountInfoParse({
            username: this.search_link,
          });
          if (data) {
            if (data.status || data["stack-trace"]) {
              this.$toast("Ошибка получения информации о пользователе");
            } else {
              this.preview_info = data;
              this.active_step = this.STEP_TWO();
              this.search_type_step_two =
                this.SEARCH_TYPE_SUBSCRIPTION_STEP_TWO();
            }
          } else {
            this.$toast("Ошибка получения информации о пользователе");
          }
        }
      }
      this.waitResponse = false;
    },
    async parsing_post_winners() {
      this.waitResponse = true;
      this.likes = this.preview_info.preview.like_count;
      this.comments = this.preview_info.preview.comment_count;
      this.active_step = this.STEP_THREE();
      this.extra_option_list = [];
      if (this.is_check_like_ST) {
        this.extra_option_list.push(IS_CHECK_LIKE_STEP_TWO);
      }
      if (this.is_check_subscription_ST) {
        this.extra_option_list.push(IS_CHECK_SUBSCRIPTION_STEP_TWO);
      }
      let data = {
        option: this.search_type_step_two,
        extra_option_list: this.extra_option_list,
        media_id: this.preview_info.preview.pk,
      };
      const resp = await this.$api.generator.startPostParseIg(data);
      if (resp) {
        if (resp.status) {
          this.$toast("Ошибка поиска");
          this.active_step = this.STEP_TWO();
        } else {
          this.winners_list = resp;
          this.active_step = this.STEP_FOUR();
        }
      } else {
        this.$toast("Ошибка поиска");
        this.active_step = this.STEP_TWO();
      }
      this.waitResponse = false;
    },
    async parsing_follower_winners() {
      this.waitResponse = true;
      // this.subscriptions = this.preview_info.preview.follower_count;
      this.subscribers = this.preview_info.preview.follower_count;
      this.active_step = this.STEP_THREE();
      let data = {
        username: this.preview_info.preview.username,
      };
      const resp = await this.$api.generator.startAccountParseIg(data);
      if (resp) {
        if (resp.status) {
          this.$toast("Ошибка получения списка подписчиков.");
          this.active_step = this.STEP_TWO();
        } else {
          this.winners_list = resp;
          this.active_step = this.STEP_FOUR();
        }
      } else {
        this.$toast("Ошибка получения списка подписчиков.");
        this.active_step = this.STEP_TWO();
      }
      this.waitResponse = false;
    },
    async generate_winner() {
      this.waitResponse = true;
      if (this.type_draw === WINNER_IG_TYPE_POST) {
        let data = {
          option: this.search_type_step_two,
          extra_option_list: this.extra_option_list,
          media_id: this.preview_info.preview.pk,
        };
        const resp = await this.$api.generator.getRandomPostWinner(data);
        if (resp) {
          if (resp.pk) {
            if (
              this.search_type_step_two === this.SEARCH_TYPE_COMMENTS_STEP_TWO()
            ) {
              this.winner = resp.user;
            } else {
              this.winner = resp;
            }
          } else {
            this.$toast("Ошибка выбора победител");
          }
        } else {
          this.$toast("Ошибка выбора победител");
        }
      } else if (this.type_draw === WINNER_IG_TYPE_SUBSCRIBERS) {
        let data = {
          username: this.preview_info.preview.username,
          ig_user_id: this.preview_info.preview.pk,
        };
        const resp = await this.$api.generator.startAccountWinner(data);
        if (resp) {
          if (resp.pk) {
            this.winner = resp;
          } else {
            this.$toast("Ошибка выбора победител");
          }
        } else {
          this.$toast("Ошибка выбора победител");
        }
      }
      this.waitResponse = false;
    },
  },
};
/*{
    "pk": "2840313406423243985",
    "id": "2840313406423243985_1442965120",
    "code": "Cdq0f6YoxzR",
    "taken_at": "2022-05-17T18:22:45+00:00",
    "media_type": 1,
    "product_type": "feed",
    "thumbnail_url": "https://scontent-hel3-1.cdninstagram.com/v/t51.2885-15/281653024_1738955799607994_4862459136691771573_n.jpg?stp=dst-jpg_e35_p1080x1080&_nc_ht=scontent-hel3-1.cdninstagram.com&_nc_cat=102&_nc_ohc=E2vzWPoGBbwAX8XohYS&edm=ALQROFkBAAAA&ccb=7-5&ig_cache_key=Mjg0MDMxMzQwNjQyMzI0Mzk4NQ%3D%3D.2-ccb7-5&oh=00_AfBnIKL5yXKcJwbWUQWpCV2ywfZ5DL1Y-31QUEEBEqu98A&oe=641416C3&_nc_sid=30a2ef",
    "location": {
        "pk": 213434615,
        "name": "Adler Microdistrict",
        "phone": "",
        "website": "",
        "category": "",
        "hours": [],
        "address": "",
        "city": "",
        "zip": null,
        "lng": 39.9242,
        "lat": 43.4261,
        "external_id": 106132402759536,
        "external_id_source": "facebook_places"
    },
    "user": {
        "pk": "1442965120",
        "username": "andryulanov",
        "full_name": "AndreyUlanov🧬",
        "profile_pic_url": "https://scontent-hel3-1.cdninstagram.com/v/t51.2885-19/254302731_4362296037227122_4178142039212644074_n.jpg?stp=dst-jpg_s150x150&_nc_ht=scontent-hel3-1.cdninstagram.com&_nc_cat=110&_nc_ohc=S1BukfRojUcAX-2hSAF&edm=ALQROFkBAAAA&ccb=7-5&oh=00_AfCooLyMHVa-ZYta-HkOWKFGc2Aa6MYmM2H5OXGq94RDaA&oe=64134156&_nc_sid=30a2ef",
        "profile_pic_url_hd": null,
        "is_private": false,
        "stories": []
    },
    "comment_count": 6,
    "comments_disabled": false,
    "like_count": 17,
    "has_liked": false,
    "caption_text": "",
    "accessibility_caption": null,
    "usertags": [],
    "sponsor_tags": [],
    "video_url": null,
    "view_count": 0,
    "video_duration": 0,
    "title": "",
    "resources": [],
    "clips_metadata": []
}*/
</script>
<style src="@/assets/scss/winner_block.css"></style>
