<template>
  <div>
    <div class="base-tabs instagram-winner__tabs block__tabs">
      <div class="base-tabs__inner">
        <a
          aria-current="page"
          class="base-tabs__tab"
          @click="$emit('change-ig-type', WINNER_IG_TYPE_POST())"
          :class="type === WINNER_IG_TYPE_POST() ? active_button_classes : ''"
        >
          <div class="base-tabs__tab-main">
            <svg xmlns="http://www.w3.org/2000/svg" class="base-tabs__tab-ico">
              <use xlink:href="#ico-card"></use>
            </svg>
            <div class="base-tabs__tab-info">
              <span>По посту </span
              ><i class="base-tabs__tab-description">(лайки, комментарии)</i>
            </div>
          </div>
        </a>
        <a
          class="base-tabs__tab"
          @click="$emit('change-ig-type', WINNER_IG_TYPE_SUBSCRIBERS())"
          :class="
            type === WINNER_IG_TYPE_SUBSCRIBERS() ? active_button_classes : ''
          "
        >
          <div class="base-tabs__tab-main">
            <svg xmlns="http://www.w3.org/2000/svg" class="base-tabs__tab-ico">
              <use xlink:href="#ico-followers"></use>
            </svg>
            <div class="base-tabs__tab-info">
              <span>Среди подписчиков </span
              ><i class="base-tabs__tab-description">Instagram</i>
            </div>
          </div>
          <!----></a
        >
      </div>
    </div>
    <div class="block__content">
      <div class="instagram-winner__form block__form">
        <div
          class="instagram-winner__form-input block__input _inst _big base-input"
        >
          <label class="base-input__inner">
            <span
              v-if="type === WINNER_IG_TYPE_POST()"
              class="base-input__label"
              >Укажите ссылку на пост:</span
            >
            <span
              v-if="type === WINNER_IG_TYPE_SUBSCRIBERS()"
              class="base-input__label"
              >Укажите username:</span
            >
            <input
              autocomplete
              type="text"
              placeholder="Ссылка на пост"
              class="base-input__input"
              v-model="ig_link"
              :disabled="waitResponse"
            />
          </label>
        </div>
        <button
          @click="$emit('first-step-find-start', ig_link)"
          class="_blue instagram-winner__form-submit block__btn-submit btn _blue"
          :disabled="waitResponse"
        >
          Найти
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  WINNER_IG_TYPE_POST,
  WINNER_IG_TYPE_SUBSCRIBERS,
} from "@/helpers/ig_winner";

export default {
  name: "WinnerIgFirstStep",
  methods: {
    WINNER_IG_TYPE_SUBSCRIBERS() {
      return WINNER_IG_TYPE_SUBSCRIBERS;
    },
    WINNER_IG_TYPE_POST() {
      return WINNER_IG_TYPE_POST;
    },
  },
  props: {
    type: String,
    waitResponse: Boolean,
  },
  data() {
    return {
      active_button_classes: "router-link-active router-link-exact-active",
      ig_link: "",
    };
  },
};
</script>

<style scoped></style>
