<template>
  <label class="custom__range" :for="name">
    <input
      type="range"
      :id="name"
      :min="min"
      :max="max"
      :step="step"
      :value="String(count)"
      @input="$emit('newValue', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: "IRange",
  props: {
    count: {
      type: Number,
      require: true,
    },
    name: {
      type: String,
      require: true,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 50,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.custom__range {
  width: 100%;
}
/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #e8e8e8;
  border-radius: 6px;
  height: 8px;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */
  /*custom styles*/
  background-color: $lilac;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
</style>
