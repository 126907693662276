<template>
  <div v-if="type === WINNER_IG_TYPE_POST()">
    <p class="block__subtitle">Выберите как присваивать номерки:</p>
    <div class="block__group">
      <div class="block__checkboxes">
        <button
          :disabled="waitResponse"
          type="button"
          class="block__checkbox"
          @click="$emit('change-search-type', SEARCH_TYPE_LIKE_STEP_TWO())"
          :class="
            search_type === SEARCH_TYPE_LIKE_STEP_TWO()
              ? active_type_classes
              : ''
          "
        >
          <span class="block__checkbox-check"></span
          ><span class="block__checkbox-wrapper"
            ><svg xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="#ico-like"></use></svg
            ><span class="block__checkbox-text _center"
              ><b>Случайный лайк</b></span
            ></span
          ></button
        ><button
          type="button"
          class="block__checkbox"
          @click="$emit('change-search-type', SEARCH_TYPE_COMMENTS_STEP_TWO())"
          :class="
            search_type === SEARCH_TYPE_COMMENTS_STEP_TWO()
              ? active_type_classes
              : ''
          "
          :disabled="waitResponse"
        >
          <span class="block__checkbox-check"></span
          ><span class="block__checkbox-wrapper"
            ><svg xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="#ico-message"></use></svg
            ><span class="block__checkbox-text _center"
              ><b>Случайный комментарий</b></span
            ></span
          >
        </button>
      </div>
    </div>
    <div class="block__checks d-md-none">
      <!--      <button type="button" class="block__checks-col">
        &lt;!&ndash;        @click="$emit('change-check-like', l_is_check_subscription)"&ndash;&gt;
        <label class="checkbox"
          ><input type="checkbox" v-model="l_is_check_subscription" /><span
            class="checkbox__inner"
            ><span class="checkbox__box"
              ><span class="checkbox__box-inner"></span></span
            ><span class="checkbox__label"
              ><span>Проверить подписку</span></span
            ></span
          ></label
        ><span class="block__checks-info"> premium </span></button
      >-->
      <button
        v-if="search_type !== SEARCH_TYPE_LIKE_STEP_TWO()"
        type="button"
        class="block__checks-col"
        :disabled="waitResponse"
      >
        <!--        @click="$emit('change-check-subscription', l_is_check_like)"-->
        <label class="checkbox"
          ><input
            type="checkbox"
            v-model="l_is_check_like"
            :disabled="waitResponse"
          /><span class="checkbox__inner"
            ><span class="checkbox__box"
              ><span class="checkbox__box-inner"></span></span
            ><span class="checkbox__label"
              ><span>Проверить лайк</span></span
            ></span
          ></label
        ><span class="block__checks-info">premium</span>
      </button>
    </div>
    <div class="block__form__setup">
      <button
        @click="$emit('parsing-post-winners')"
        class="block__btn-submit btn _fw500 _blue"
        :disabled="waitResponse"
      >
        <span>Выбрать победителя</span
        ><svg xmlns="http://www.w3.org/2000/svg">
          <use xlink:href="#arrow-double-right"></use>
        </svg>
      </button>
    </div>
  </div>
  <div
    class="followers two-step"
    v-else-if="type === WINNER_IG_TYPE_SUBSCRIBERS()"
  >
    <p class="block__subtitle d-md-none">Данные аккаунта</p>
    <div class="block__count">
      <span class="block__count-text">Подписок:</span
      ><span class="block__count-count">{{ preview.following_count }}</span
      ><svg xmlns="http://www.w3.org/2000/svg">
        <use xlink:href="#ico-people"></use>
      </svg>
    </div>
    <div class="block__count">
      <span class="block__count-text">Подписчиков:</span
      ><span class="block__count-count">{{ preview.follower_count }}</span
      ><svg xmlns="http://www.w3.org/2000/svg">
        <use xlink:href="#ico-people"></use>
      </svg>
    </div>
    <div class="block__form">
      <button
        @click="$emit('parsing-follower-winners')"
        class="block__btn-submit btn _fw500 _blue"
        :disabled="waitResponse"
      >
        <span>Выбрать победителя</span
        ><svg xmlns="http://www.w3.org/2000/svg">
          <use xlink:href="#arrow-double-right"></use>
        </svg>
      </button>
    </div>
  </div>
  <div v-else></div>
</template>
<script>
import {
  SEARCH_TYPE_COMMENTS_STEP_TWO,
  SEARCH_TYPE_LIKE_STEP_TWO,
  WINNER_IG_TYPE_POST,
  WINNER_IG_TYPE_SUBSCRIBERS,
} from "@/helpers/ig_winner";

export default {
  name: "WinnerIgTwoStep",
  methods: {
    SEARCH_TYPE_COMMENTS_STEP_TWO() {
      return SEARCH_TYPE_COMMENTS_STEP_TWO;
    },
    SEARCH_TYPE_LIKE_STEP_TWO() {
      return SEARCH_TYPE_LIKE_STEP_TWO;
    },
    WINNER_IG_TYPE_SUBSCRIBERS() {
      return WINNER_IG_TYPE_SUBSCRIBERS;
    },
    WINNER_IG_TYPE_POST() {
      return WINNER_IG_TYPE_POST;
    },
  },
  props: {
    type: String,
    search_type: String,
    is_check_like: Boolean,
    is_check_subscription: Boolean,
    preview: Object,
    waitResponse: Boolean,
  },
  data() {
    return {
      active_type_classes: "_active",
      l_is_check_like: false,
      l_is_check_subscription: false,
    };
  },
  watch: {
    is_check_like(n) {
      this.l_is_check_like = n;
    },
    is_check_subscription(n) {
      this.l_is_check_subscription = n;
    },
    l_is_check_like(n) {
      this.$emit("change-check-like", n);
    },
    l_is_check_subscription(n) {
      this.$emit("change-check-subscription", n);
    },
  },
};
</script>

<style scoped></style>
