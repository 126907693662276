<template>
  <a :href="link" class="banner"
    ><img src="@/assets/images/banner.jpg" alt=""
  /></a>
</template>

<script>
export default {
  name: "BannerLink",
  props: ["link"],
};
</script>

<style lang="scss" scoped>
a {
  transition: 0.2s ease-in;
  &:hover {
    outline: 2px solid $lilac;
  }
}
</style>
